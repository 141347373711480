const ROUTES = {
  APP: 'App',
  AUTH: 'Auth',
  AUTOMATION: 'Automation',
  AUTOMATION_PREVIEW: 'AutomationPreview',
  AUTOMATION_RULE_EDIT: 'AutomationRuleEdit',
  AUTOMATION_RULES: 'AutomationRules',
  BLOCK_BLUE_BAND_INDEX: 'BlockBlueBandIndex',
  BLOCK_DETAILS: 'BlockDetails',
  BLOCK_EVENT_LIST: 'BlockEventList',
  CONSOLE: 'Console',
  CONSOLE_DETAILS: 'ConsoleDetails',
  DASHBOARD: 'Dashboard',
  DEVICES: 'Devices',
  DEVICE_DETAILS: 'DeviceDetails',
  FLOW_STATION: 'FlowStation',
  FLOW_STATION_PREVIEW: 'FlowStationPreview',
  FORGOT_PASSWORD: 'ForgotPassword',
  FORGOT_USERNAME: 'ForgotUsername',
  IRRIGATION: 'Irrigation',
  LOADING: 'Loading',
  MANAGE_EVENT: 'ManageEvent',
  MAP: 'Map',
  NOTES: 'Notes',
  NOTE_EDIT: 'NoteEdit',
  NOTE_BLOCK_LIST: 'NoteBlockList',
  PROFILE: 'Profile',
  REPORT: 'Report',
  REPORT_PREVIEW: 'ReportPreview',
  SCHEDULE: 'Schedule',
  SCHEDULE_EVENT_LIST: 'ScheduleEventList',
  SIGN_IN: 'SignIn',
  SITE_BLUE_BAND_INDEX: 'SiteBlueBandIndex',
  SITE_SELECTOR: 'SiteSelector',
  TEMPERATURE: 'Temperature',
  TEMPERATURE_PREVIEW: 'TemperaturePreview',
  WEATHER: 'Weather',
  WEATHER_FORECASTS: 'WeatherForecasts',
  WEATHER_PREVIEW: 'WeatherPreview',
  NUTRIENTS: 'Nutrients',
};

export default ROUTES;
