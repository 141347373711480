import React from 'react';
import PropTypes from 'prop-types';
import { StatusBar, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

// services
import { navigationShape } from '../../shapes/navigation';
import { isIos } from '../../services/PlatformService';

// styles
import { globalStyles } from '../../styles';

// constants
import COLORS from '../../colors';

// components
import Divider from '../../components/Divider';
import HeaderBar from '../../components/header/HeaderBar';

const NutrientsScreen = ({ navigation, route }) => {
  const currentSite = useSelector((state) => state.site.currentSite);

  return (
    <SafeAreaView style={globalStyles.topContainer} edges={['top', 'right', 'left']}>
      <StatusBar backgroundColor={COLORS.greyishBrown} barStyle={isIos() ? 'dark-content' : 'default'} translucent={false} />

      <View style={globalStyles.header} testID="nutrients__subscreen-container">
        <HeaderBar siteName={currentSite.name} screenName={'nutirents'} testId={'nutrients__header'} navigation={navigation} />
        <Text>Nutrients</Text>
      </View>

      <Divider />
    </SafeAreaView>
  );
};

NutrientsScreen.propTypes = {
  navigation: navigationShape.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      reloadData: PropTypes.bool,
    }),
  }),
};

export default NutrientsScreen;
